import React from "react";

export default function LoadingCircle() {
  return (
    <>
      <div className="spinner-circle">
        <div className="spinner-item"></div>
        <div className="spinner-item"></div>
      </div>
    </>
  );
}
