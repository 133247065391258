import React from "react";

export default function FooterV2() {
  return (
    <footer className="main-footer">
      <div className="d-flex justify-content-center">
        Copyright & copy; 2022 <div className="bullet" /> Nadira
      </div>{" "}
    </footer>
  );
}
