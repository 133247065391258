export const Data = {
  menus: [
    {
      name: "Dashboard",
      icon: "fas fa-home",
      url: "/",
      privilages: [
        "pic",
        "uppm",
        "director",
        "reviewer",
        "dosen",
        "admin",
        "external",
      ],
    },

    {
      header: true,
      name: "Admin",
      privilages: ["admin"],
    },
    {
      name: "Manage User",
      icon: "fas fa-users",
      url: "/manage-user",
      privilages: ["admin"],
    },
    {
      name: "Periode",
      icon: "fas fa-calendar",
      url: "/periode",
      privilages: ["admin"],
    },
    {
      name: "Sumber Dana",
      icon: "fas fa-wallet",
      url: "/sumber-dana",
      privilages: ["admin"],
    },
    {
      name: "Prodi",
      icon: "fas fa-university",
      url: "/prodi",
      privilages: ["admin"],
    },
    {
      name: "Category",
      icon: "fas fa-th-list",
      url: "/category",
      privilages: ["admin"],
    },

    {
      header: true,
      name: "Uppm",
      privilages: ["uppm"],
    },
    {
      name: "Permintaan Pengajuan",
      icon: "fas fa-layer-group",
      url: "/proposal-request",
      privilages: ["uppm", "admin"],
    },
    {
      name: "List Proposal",
      icon: "fas fa-list",
      url: "/proposal",
      privilages: ["uppm", "admin"],
    },

    {
      header: true,
      name: "Review",
      privilages: ["pic", "uppm", "director", "reviewer", "admin"],
    },
    {
      name: "Review Penelitian",
      icon: "fas fa-hourglass",
      url: "/penelitian/review",
      privilages: ["pic", "uppm", "director", "reviewer", "admin"],
    },
    {
      name: "Review Pengabdian",
      icon: "fas fa-hourglass",
      url: "/pengabdian/review",
      privilages: ["pic", "uppm", "director", "reviewer", "admin"],
    },
    {
      name: "Review Final Upload Laporan",
      icon: "fas fa-hourglass",
      url: "/final-upload/review",
      privilages: ["pic", "uppm", "admin"],
    },

    {
      header: true,
      name: "Final Upload Laporan",
      privilages: ["dosen"],
    },
    {
      name: "Final Upload Laporan",
      icon: "fas fa-file-upload",
      url: "/final-upload",
      privilages: ["dosen"],
    },
    {
      header: true,
      name: "Penelitian",
      privilages: ["dosen", "external"],
    },
    {
      name: "Data Penelitian",
      icon: "fas fa-list",
      url: "/penelitian",
      privilages: ["dosen", "external"],
    },
    {
      name: "Pengajuan Penelitian",
      icon: "fas fa-scroll",
      url: "/penelitian/pengajuan",
      privilages: ["dosen"],
    },

    {
      header: true,
      name: "Pengabdian",
      privilages: ["dosen", "external"],
    },
    {
      name: "Data Pengabdian",
      icon: "fas fa-list",
      url: "/pengabdian",
      privilages: ["dosen", "external"],
    },
    {
      name: "Pengajuan Pengabdian",
      icon: "fas fa-scroll",
      url: "/pengabdian/pengajuan",
      privilages: ["dosen"],
    },

    {
      header: true,
      name: "Riwayat",
      privilages: ["dosen"],
    },
    {
      name: "Riwayat Proposal",
      icon: "fas fa-history",
      url: "/riwayat-proposal",
      privilages: ["dosen"],
    },
  ],
};
