import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Switch } from "react-router";
import LoadingCircle from "../components/LoadingCircle";
import AuthRouteV2 from "../components/Routes/AuthRouteV2";
import authMenu from "./auth-menu";

export const LayoutAuth = ({ user }) => {
  const checkPrivilages = (e) => {
    if (e.privilages) {
      return e.privilages.find(
        (p) => p === user?.privilages?.find((f) => f === p)
      );
    }

    return true;
  };

  const generatesRoutes = () => {
    return authMenu()
      .filter(checkPrivilages)
      .map((props, idx) => {
        if (props?.url && (props?.redirect || props?.component)) {
          return (
            <AuthRouteV2
              key={idx}
              path={props.url}
              exact={!!props.exact}
              component={props.component}
              {...props}
            />
          );
        } else {
          return null;
        }
      });
  };

  return (
    <>
      <Suspense
        fallback={
          <>
            <div className="main-content">
              <section className="section mt-3">
                <div className="d-flex flex-column justify-content-center align-self-center align-items-center vh-100">
                  <LoadingCircle />
                </div>
              </section>
            </div>
          </>
        }
      >
        <Switch>{generatesRoutes()}</Switch>
      </Suspense>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAuth);
