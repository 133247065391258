import React, { useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, Route, useLocation } from "react-router";
import { Spinner } from "reactstrap";
import setAuthorizationHeader from "../../configs/axios/setAuthorizationHeader";
import users from "../../constants/api/users";

import { populateProfile } from "../../store/actions/user";
import { mySwalError } from "../../utils/mySwal";

const getMe = ({ dispatch }) => {
  const tokenCookies =
    decodeURIComponent(window.document.cookie)
      ?.split(";")
      ?.find?.((item) => item.indexOf("NADIRA:token") > -1)
      ?.split("=")[1] ?? null;

  if (tokenCookies) {
    setAuthorizationHeader(tokenCookies);
    // API
    users
      .getme()
      .then((res) => {
        dispatch(populateProfile(res?.data));
      })
      .catch((err) => {
        mySwalError({
          error: err?.response?.data?.message,
          status: err?.response?.status,
        });
        const expires = new Date(
          new Date().getTime() - 7 * 24 * 60 * 60 * 1000
        );
        document.cookie = `NADIRA:token=${null}; expires=${expires.toUTCString()}; path=/;`;
      })
      .finally();

    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: "rgba(255,255,255, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner style={{ width: 48, height: 48 }} />
      </div>
    );
  }
};

export const AuthRouteV2 = ({ isLoggedIn, user, type, ...props }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const mustAuth = useMemo(() => ["guest", "private"].includes(type), [type]);

  if (mustAuth) {
    if (user === null) {
      getMe({ dispatch });
    }
  }

  if (type === "guest" && isLoggedIn === true) {
    const search = new URLSearchParams(location.search);
    if (location.pathname === "/auth/login") {
      if (search.get("to")) {
        return <Redirect to={search.get("to")} />;
      }
    }
    return <Redirect to={"/"} />;
  }

  if (type === "private" && isLoggedIn === false) {
    const searchParams = new URLSearchParams(location.search);
    if (location.pathname !== "/auth/login") {
      searchParams.set("to", location.pathname);
    }
    return <Redirect to={"/auth/login?" + searchParams.toString()} />;
  }

  return <Route {...props} />;
};

const mapStateToProps = ({ user }) => ({
  isLoggedIn: user !== null,
  user,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuthRouteV2);
