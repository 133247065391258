import { lazy } from "react";

const authMenu = () => [
  {
    url: "/",
    exact: true,
    component: lazy(() => import("../pages/Pages/Dashboard")),
    privilages: [
      "pic",
      "uppm",
      "director",
      "reviewer",
      "dosen",
      "admin",
      "external",
    ],
  },

  {
    url: "/riwayat-proposal",
    exact: true,
    component: lazy(() => import("../pages/Pages/Profile/RiwayatProposal")),
    privilages: ["dosen"],
  },
  {
    url: "/riwayat-proposal/:id/detail",
    exact: true,
    component: lazy(() =>
      import("../pages/Pages/Profile/DetailRiwayatProposal")
    ),
    privilages: ["dosen"],
  },
  {
    url: "/final-upload",
    exact: true,
    component: lazy(() =>
      import("../pages/Pages/FinalUploadProposal/ListData")
    ),
    privilages: ["dosen"],
  },
  {
    url: "/final-upload/:id/revisi",
    exact: true,
    component: lazy(() => import("../pages/Pages/FinalUploadProposal/Revisi")),
    privilages: ["dosen"],
  },
  {
    url: "/final-upload/:id/detail",
    exact: true,
    component: lazy(() => import("../pages/Pages/FinalUploadProposal/Detail")),
    privilages: ["dosen"],
  },
  {
    url: "/final-upload/review",
    exact: true,
    component: lazy(() =>
      import("../pages/Pages/FinalUploadProposal/ReviewListData")
    ),
    privilages: ["pic", "uppm", "admin"],
  },
  {
    url: "/final-upload/review/:id",
    exact: true,
    component: lazy(() => import("../pages/Pages/FinalUploadProposal/Review")),
    privilages: ["pic", "uppm", "admin"],
  },

  {
    url: "/pengabdian",
    exact: true,
    component: lazy(() => import("../pages/Pages/Pengabdian/ListData")),
    privilages: ["dosen", "external"],
  },
  {
    url: "/pengabdian/pengajuan",
    exact: true,
    component: lazy(() => import("../pages/Pages/Pengabdian/Pengajuan")),
    privilages: ["dosen"],
  },
  {
    url: "/pengabdian/:id/detail",
    exact: true,
    component: lazy(() => import("../pages/Pages/Pengabdian/Detail")),
    privilages: ["dosen", "external", "admin"],
  },
  {
    url: "/pengabdian/:id/edit",
    exact: true,
    component: lazy(() => import("../pages/Pages/Pengabdian/Edit")),
    privilages: ["dosen", "admin"],
  },
  {
    url: "/pengabdian/:id/logbook",
    exact: true,
    component: lazy(() => import("../pages/Pages/Pengabdian/Logbook")),
    privilages: ["dosen"],
  },
  {
    url: "/pengabdian/:id/revisi",
    exact: true,
    component: lazy(() => import("../pages/Pages/Pengabdian/Revisi")),
    privilages: ["dosen"],
  },
  {
    url: "/pengabdian/review",
    exact: true,
    component: lazy(() => import("../pages/Pages/Pengabdian/ReviewListData")),
    privilages: ["pic", "uppm", "director", "reviewer", "admin"],
  },
  {
    url: "/pengabdian/review/:id",
    exact: true,
    component: lazy(() => import("../pages/Pages/Pengabdian/Review")),
    privilages: ["pic", "uppm", "director", "reviewer", "admin"],
  },

  {
    url: "/penelitian",
    exact: true,
    component: lazy(() => import("../pages/Pages/Penelitian/ListData")),
    privilages: ["dosen", "external"],
  },
  {
    url: "/penelitian/pengajuan",
    exact: true,
    component: lazy(() => import("../pages/Pages/Penelitian/Pengajuan")),
    privilages: ["dosen"],
  },
  {
    url: "/penelitian/:id/detail",
    exact: true,
    component: lazy(() => import("../pages/Pages/Penelitian/Detail")),
    privilages: ["dosen", "external", "admin"],
  },
  {
    url: "/penelitian/:id/edit",
    exact: true,
    component: lazy(() => import("../pages/Pages/Penelitian/Edit")),
    privilages: ["dosen", "admin"],
  },
  {
    url: "/penelitian/:id/logbook",
    exact: true,
    component: lazy(() => import("../pages/Pages/Penelitian/Logbook")),
    privilages: ["dosen"],
  },
  {
    url: "/penelitian/:id/revisi",
    exact: true,
    component: lazy(() => import("../pages/Pages/Penelitian/Revisi")),
    privilages: ["dosen"],
  },
  {
    url: "/penelitian/review",
    exact: true,
    component: lazy(() => import("../pages/Pages/Penelitian/ReviewListData")),
    privilages: ["pic", "uppm", "director", "reviewer", "admin"],
  },
  {
    url: "/penelitian/review/:id",
    exact: true,
    component: lazy(() => import("../pages/Pages/Penelitian/Review")),
    privilages: ["pic", "uppm", "director", "reviewer", "admin"],
  },

  {
    url: "/manage-user",
    exact: true,
    component: lazy(() => import("../pages/Pages/Admin/ManageUser")),
    privilages: ["admin"],
  },
  {
    url: "/manage-user/:id/edit",
    exact: true,
    component: lazy(() => import("../pages/Pages/Admin/EditUser")),
    privilages: ["admin"],
  },
  {
    url: "/add-user",
    exact: true,
    component: lazy(() => import("../pages/Pages/Admin/AddUser")),
    privilages: ["admin"],
  },
  {
    url: "/periode",
    exact: true,
    component: lazy(() => import("../pages/Pages/Admin/Periode")),
    privilages: ["admin"],
  },
  {
    url: "/sumber-dana",
    exact: true,
    component: lazy(() => import("../pages/Pages/Admin/SourceFund")),
    privilages: ["admin"],
  },
  {
    url: "/prodi",
    exact: true,
    component: lazy(() => import("../pages/Pages/Admin/Prodi")),
    privilages: ["admin"],
  },
  {
    url: "/category",
    exact: true,
    component: lazy(() => import("../pages/Pages/Admin/CategoryOutput")),
    privilages: ["admin"],
  },
  {
    url: "/proposal/:id/anggaran",
    exact: true,
    component: lazy(() => import("../pages/Pages/Admin/BigFund")),
    privilages: ["admin", "uppm"],
  },

  {
    url: "/proposal-request",
    exact: true,
    component: lazy(() =>
      import("../pages/Pages/Uppm/AcceptSubmissionRequest")
    ),
    privilages: ["uppm", "admin"],
  },
  {
    url: "/proposal",
    exact: true,
    component: lazy(() => import("../pages/Pages/Uppm/ListProposal")),
    privilages: ["uppm", "admin"],
  },

  {
    url: "/profile",
    exact: true,
    component: lazy(() => import("../pages/Pages/Profile/DetailProfile")),
    privilages: [
      "pic",
      "uppm",
      "director",
      "reviewer",
      "dosen",
      "admin",
      "external",
    ],
  },
  {
    url: "/profile/change-password",
    exact: true,
    component: lazy(() => import("../pages/Pages/Profile/ChangePassword")),
    privilages: [
      "pic",
      "uppm",
      "director",
      "reviewer",
      "dosen",
      "admin",
      "external",
    ],
  },

  {
    url: "*",
    exact: true,
    component: lazy(() => import("../pages/Pages/Errors/404V2")),
    privilages: [
      "pic",
      "uppm",
      "director",
      "reviewer",
      "dosen",
      "admin",
      "external",
    ],
  },
];

export default authMenu;
