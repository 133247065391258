import axios from "../../configs/axios";

const users = {
  login: (credentials) => axios.post(`/auth/login`, credentials),
  getme: () => axios.get("/me"),
  forgotPassword: (credentials) => axios.post(`/auth/forgot`, credentials),
  resetPassword: (credentials) => axios.post(`/reset-password`, credentials),
  dashboard: () => axios.get("/dashboard"),

  logout: () => axios.post("/logout"),
  updatePassword: ({ payload, id }) =>
    axios.post(`/update-password/${id}`, payload),
};

export default users;
