import axios from "axios";
import errorHandler from "./errorHandler";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

instance.interceptors.response.use((response) => response.data, errorHandler);

export default instance;

export const requestDownload = (url, filename = "download") => {
  return instance
    .get(url, { responseType: "arraybuffer" })
    .then((res) => {
      // const type = res.headers["content-type"];
      const type = "application/pdf";
      const blob = new Blob([res], { type, encoding: "UTF-8" });
      let name = filename;
      // const disposition = res.headers["content-disposition"];
      const disposition = `inline; filename="LembarPengesahan.pdf"`;
      if (disposition && disposition.indexOf("inline") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          name = matches[1].replace(/['"]/g, "");
        }
      }

      const URL = window.URL || window.webkitURL;
      const downloadUrl = URL.createObjectURL(blob);
      let newWindow = null;

      const iOS =
        window.navigator.platform &&
        /iPad|iPhone|iPod/.test(window.navigator.platform);
      if (iOS) {
        const reader = new FileReader();
        reader.onload = function(e) {
          newWindow = window.open(reader.result);
          newWindow.onload = function() {
            newWindow.document
              .getElementsByTagName("html")[0]
              .appendChild(document.createElement("head"))
              .appendChild(document.createElement("title"))
              .appendChild(document.createTextNode(name));
          };
          setTimeout(() => {
            newWindow.document.title = name;
          }, 100);
        };
        reader.readAsDataURL(blob);
      } else {
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = name;
        link.click();
        setTimeout(() => {
          link.remove();
        }, 1500);
      }

      console.log("scc fnc", res);
      return {
        response: { status: 200 },
      };
    })
    .catch((err) => {
      console.log("err fnc", err);
      return err;
    });
};
