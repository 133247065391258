import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { FooterV2 } from "./components/admin";

import { useLocation } from "react-router-dom";
import HeaderV2 from "./components/admin/headerV2";
import SideBarV2 from "./components/admin/sidebarV2";

import { setLocale as setValidationLocale } from "yup";
import validationLocaleID from "./utils/yup/locales/id";

import moment from "moment";
import "moment/locale/id";
import LoadingCircle from "./components/LoadingCircle";
import AuthRouteV2 from "./components/Routes/AuthRouteV2";
import LayoutAuth from "./containers/LayoutAuth";
moment.locale("id");

const Login = React.lazy(() => import("./pages/Pages/Authenticate/Login"));
const ForgotPassword = React.lazy(() =>
  import("./pages/Pages/Authenticate/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./pages/Pages/Authenticate/ResetPassword")
);

const history = React.lazy(() => import("./history"));

setValidationLocale(validationLocaleID);

function App() {
  let location = useLocation().pathname;

  let locationSplit = location.split("/");
  let locationParent = locationSplit[1];

  return (
    <div className="App">
      <>
        <HeaderV2 className={locationParent === "auth" ? "d-none" : ""} />
        <SideBarV2 className={locationParent === "auth" ? "d-none" : ""} />
        <React.Suspense
          fallback={
            <>
              <div className="main-content">
                <section className="section mt-3">
                  <div className="d-flex flex-column justify-content-center align-self-center align-items-center vh-100">
                    <LoadingCircle />
                  </div>
                </section>
              </div>
            </>
          }
        >
          <Switch history={history}>
            <Redirect exact from="/auth" to="/auth/login" />
            <Redirect exact from="/login" to="/auth/login" />
            <Redirect exact from="/auth/reset-password" to="/auth/login" />
            <AuthRouteV2
              type="guest"
              path="/auth/login"
              exact
              component={Login}
            />
            <AuthRouteV2
              type="guest"
              path="/auth/forgot-password"
              exact
              component={ForgotPassword}
            />
            <AuthRouteV2
              type="guest"
              path="/auth/reset-password/:token"
              exact
              component={ResetPassword}
            />

            {/* AUTH ROUTE */}
            <AuthRouteV2 type="private" exact component={LayoutAuth} />
          </Switch>
        </React.Suspense>
        <FooterV2 />
      </>
    </div>
  );
}

export default App;
