import { POPULATE_PROFILE } from "../../constants/types/user";

const initialState = null;

const user = function(state = initialState, action) {
  switch (action.type) {
    case POPULATE_PROFILE:
      return action.payload;

    default:
      return state;
  }
};

export default user;
