// import axios, { setAuthorizationHeader } from "./index";

export default async function errorHandler(error) {
  if (error) {
    let message;
    if (error.response) {
      const originalRequest = error.config;

      if (error.response.status === 500)
        message = "Something went terribly wrong";
      else if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
      } else message = error.response.data.message;

      if (
        typeof message === "string" &&
        message === "Sesi tidak sah atau telah usang, silahkan login kembali."
      ) {
        const expires = new Date(
          new Date().getTime() - 7 * 24 * 60 * 60 * 1000
        );
        document.cookie = `NADIRA:token=${null}; expires=${expires.toUTCString()}; path=/;`;
      }

      return Promise.reject(error);
    }
  }
}
